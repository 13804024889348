.skills {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 70%;
  box-sizing: border-box;
}

.description {
  color: var(--Cinder-Light, #656d72);
  font: 400 16px Inter, -apple-system, Roboto, Helvetica, sans-serif;
  margin: 0 0 18px;
  text-align: left;
}
.skillsList {
  display: flex;

  flex-direction: column;
  justify-content: space-around;
  height: 80%;
}

@media (max-width: 991px) {
  .skills {
    padding: 40px 20px;
    width: 100%;
  }

  .description {
    margin-bottom: 40px;
  }
}
