.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 1440px;
  width: 100%;
}

.main {
  flex: 1;
  padding: 0;
}

@media (max-width: 991px) {
  .main {
    padding: 0 20px;
  }
}
@media (max-width: 691px) {
  .main {
    padding: 0;
  }
}
