:root {
  --bg-color: #fff;
  --text-color: #232e35;
  --primary-color: #7e74f1;
  --White: #fff;
  --Light-1: #fbfbfb;
  --Light-2: #f1f1f1;
  --Light-3: #f5f5f5;
  --Light-4: #d9d9d9;
  --Cinder: #232e35;
  --Cinder-Light: #656d72;
  --Purple: #7e74f1;
  --Purple-Light: #f5f3fe;
  --Purple-Lighter: #eae6fe;
}

body.dark-mode {
  --bg-color: #181818;
  --text-color: #fff;
  --primary-color: #a59cf7;
  --White: #232e35;
  --Light-1: #3a3d41;
  --Light-2: #2f3336;
  --Light-3: #2c2f31;
  --Light-4: #1a1d1f;
  --Cinder: #f1f1f1;
  --Cinder-Light: #d9d9d9;
  --Purple: #a59cf7;
  --Purple-Light: #3a3645;
  --Purple-Lighter: #2f2d3a;
}

body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.modal-open {
  overflow: hidden;
}

.App {
  text-align: center;
  background-color: var(--bg-color);
  justify-content: center;
  align-items: center;
  display: flex;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
