.educationItem {
  margin-bottom: 64px;
  max-width: 371px;
}

.header {
  display: flex;
  align-items: center;
  gap: 20px;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--Light-2, #f1f1f1);
}

.institution {
  color: var(--Cinder, #232e35);
  font: 500 16px Plus Jakarta Sans, -apple-system, Roboto, Helvetica, sans-serif;
  margin: 0;
}

.details {
  margin-left: 36px;
  margin-top: 10px;
  text-align: left;
}

.degree {
  color: var(--Cinder-Light, #656d72);
  font: 400 14px Inter, sans-serif;
}

.period {
  color: var(--Cinder, #232e35);
  font: 500 14px Inter, sans-serif;
  margin-top: 24px;
}

@media (max-width: 991px) {
  .educationItem {
    margin-bottom: 40px;
  }
}
