.experience {
  background-color: var(--Light-1);
  color: var(--text-color);
  width: 100%;
  padding: 109px 40px;

  box-sizing: border-box;
}

.container {
  display: flex;
  width: 100%;
  gap: 40px;
}

.sidebar {
  width: 27%;
}

.header {
  display: flex;
  gap: 16px;
  align-items: center;
}

.aboveTitleLine {
  background-color: var(--primary-color);
  width: 16px;
  height: 2px;
}

.aboveTitle {
  color: var(--Cinder-Light, #656d72);
  letter-spacing: 7.5px;
  font: 500 12px/24px Inter, -apple-system, Roboto, Helvetica, sans-serif;
  text-transform: uppercase;
}

.title {
  color: var(--Cinder, #232e35);
  margin-top: 8px;
  font: 700 36px Plus Jakarta Sans, -apple-system, Roboto, Helvetica, sans-serif;
  text-align: left;
}

.companyList {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}

.companyButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background-color: var(--Light-3);
  color: var(--text-color);
  font: 500 16px Inter, sans-serif;
  padding: 14px 32px;
  margin-bottom: 24px;
  border: none;
  cursor: pointer;
}

.companyIcon {
  width: 24px;
  aspect-ratio: 1;
  object-fit: contain;
}

.content {
  width: 73%;
}

.activeButton {
  /* Add styles for the active button state */
  font-weight: bold;
  background-color: var(--Light-2); /* or any color you prefer */
}

@media (max-width: 991px) {
  .experience {
    padding: 100px 10px;
  }

  .container {
    flex-direction: column;
  }

  .sidebar,
  .content {
    width: 100%;
  }

  .companyList {
    margin-top: 40px;
  }
}

/* Accessibility-focused styles */
.companyButton:focus-visible {
  outline: 3px solid #007bff;
  outline-offset: 2px;
  position: relative;
  z-index: 1;
}

.content:focus-visible {
  outline: none;
  box-shadow: 0 0 0 3px #007bff;
}

/* High contrast support */
@media (forced-colors: active) {
  .activeButton {
    border: 2px solid CanvasText;
  }

  .companyButton:focus-visible {
    outline: 2px solid CanvasText;
  }
}

/* Reduced motion preferences */
@media (prefers-reduced-motion: reduce) {
  .companyButton {
    transition: none;
  }
}

/* Support for Windows High Contrast mode */
@media screen and (-ms-high-contrast: active) {
  .activeButton {
    border: 2px solid WindowText;
  }
}
