.serviceItem {
  border-radius: 8px;
  background: var(--bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding: 53px 61px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--Purple-Light, #f5f3fe);
  width: 56px;
  height: 56px;
}

.iconImage {
  width: 24px;
  aspect-ratio: 1;
  object-fit: contain;
}

.title {
  color: var(--Cinder, #232e35);
  margin: 20px 0;
  font: 500 16px Plus Jakarta Sans, -apple-system, Roboto, Helvetica, sans-serif;
  text-align: center;
}

.description {
  color: var(--Cinder, #232e35);
  text-align: center;
  font: 400 14px/24px Inter, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
  .serviceItem {
    padding: 53px 20px;
  }
}
