.themeToggle {
  background-color: transparent !important;
  border: none !important;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.themeToggle:focus,
.themeToggle:active,
.themeToggle:hover {
  background-color: transparent !important;
  outline: none;
}

.toggleIcon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

/* Optional: add a subtle hover effect */
.themeToggle:hover .toggleIcon {
  transform: scale(1.1);
}

/* If you want to add a custom focus indicator, you can use something like this: */
.themeToggle:focus-visible {
  box-shadow: 0 0 0 2px var(--primary-color);
  border-radius: 50%;
}
