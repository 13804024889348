.skillBar {
  margin-bottom: 10px;
}

.label {
  color: var(--Cinder, #232e35);
  font: 500 14px Inter, sans-serif;
  margin-bottom: 16px;
  display: block;
}

.barContainer {
  background: var(--Purple-Light, #f5f3fe);
  border-radius: 2px;
  height: 2px;
  width: 100%;
}

.bar {
  background: var(--Purple, #7e74f1);
  border-radius: 2px 0 0 2px;
  height: 100%;
}
