.portfolioItem {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 361px;
  margin-left: auto;
  margin-right: auto;
}

.imageContainer {
  height: 295px;
  background-color: #f0f0f0;
  filter: contrast(0.7);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details {
  border-radius: 0 0 8px 8px;
  border: 1px solid var(--Light-3, #f5f5f5);
  border-top: none;
  background: var(--White, #fff);
  padding: 24px;
}

.title {
  color: var(--Cinder, #232e35);
  font: 500 16px Plus Jakarta Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.tagContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 15px;
  flex-wrap: wrap;
}

.tag {
  border-radius: 4px;
  border: 1px solid var(--Light-2, #f1f1f1);
  color: var(--Cinder-Light, #656d72);
  font: 500 12px Inter, sans-serif;
  padding: 4px 8px;
}

.tagIcon {
  width: 24px;
  aspect-ratio: 1;
  object-fit: contain;
}

@media (max-width: 991px) {
  .details {
    padding: 20px;
  }
}
