/* ::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}


::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 1200px;
  height: 90vh;
  position: relative;
}

.scrollableContent {
  height: 100%;
  overflow-y: auto;
  padding: 30px;
  box-sizing: border-box;
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 28px;
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  z-index: 2;
}

.imageMontage {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  margin: 20px 0;
}

.imageContainer {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.imageContainer:hover {
  transform: scale(1.02);
}

.montageImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.projectInfo {
  margin-top: 30px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
}

.tag {
  background: #f0f0f0;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 14px;
}

.description {
  line-height: 1.6;
  margin-bottom: 20px;
  color: #333;
  text-align: left;
}

.technologies {
  margin-bottom: 20px;
}

.technologies h3 {
  margin-bottom: 10px;
  color: #222;
  text-align: left;
}

.techList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.techItem {
  background: #e8e8e8;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
}

.projectLink {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.projectLink:hover {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .imageMontage {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
  }

  .modalContent {
    width: 95%;
    height: 95vh;
  }

  .scrollableContent {
    padding: 20px;
  }
}
